import { createStore, combineReducers } from 'redux'
import authReducer from './reducers/authReducers'
import accountReducer from './reducers/accountReducer';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false
}

const viewReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}


const rootReducer = combineReducers({
  auth: authReducer,
  view: viewReducer,
  account: accountReducer,
});

const store = createStore(rootReducer);

export default store;