import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import api from './api.json'
import store from './store'

import { icons } from './assets/icons'

React.icons = icons

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: api.CognitoRegion,
    userPoolId: api.UserPoolId,
    userPoolWebClientId: api.WebClientId
  },
  API: {
    endpoints: [
      {
        name: 'ExchangeAPI',
        endpoint: api.ExchangeApi,
        region: 'us-east-1'
      },
      {
        name: 'ExchangeAPISecure',
        endpoint: api.ExchangeApiSecure,
        region: 'us-east-1'
      }
    ]
  }
})
ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
