// src/reducers/accountReducer.js
import { SET_ACCOUNT, SET_ERRORS, CLEAR_ERRORS } from '../actions/types';

const initialState = {
  account: null,
  errors: [],
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: [...state.errors, ...action.payload],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

export default accountReducer;
